"use strict";

jQuery(document).ready(function (jQuery) {
  jQuery(document).foundation();
  var nav = jQuery("nav#site-navigation");

  // Open het menu
  jQuery('button#openMenu').click(function () {
    nav.addClass("open");
    console.log("Menu opened"); // Debugging
  });

  // Sluit het menu
  console.log(jQuery('button#closeMenu').length);
  jQuery('button#closeMenu').click(function () {
    console.log("Close button clicked"); // Debugging
    nav.removeClass("open");
  });
  function isScrolledIntoView(elem) {
    var docViewTop = jQuery(window).scrollTop();
    var docViewBottom = docViewTop + (jQuery(window).height() + 200); // 200px offset for earlier response

    var elemTop = jQuery(elem).offset().top;
    var elemBottom = elemTop + jQuery(elem).height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  jQuery('button#primary-mobile-menu').click(function () {
    nav.removeClass("open");
  });
  var ul = jQuery("nav#site-navigation ul li ul");
  var li = jQuery("nav#site-navigation ul li ul li");
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    jQuery("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }
  jQuery(document).mouseup(function (e) {
    if (!nav.is(e.target) // if the target of the click isn't the container...
    && nav.has(e.target).length === 0)
      // ... nor a descendant of the container
      {
        nav.removeClass('open');
      }
  });
  jQuery('.open-search-form').click(function () {
    jQuery('section').addClass("open-search");
    jQuery('body').addClass("search-main");
  });
  jQuery('#deleteSearch').click(function () {
    jQuery('section').removeClass("open-search");
    jQuery('body').removeClass("search-main");
  });
  jQuery(window).on('scroll touchmove', function () {
    jQuery('.fade-in').each(function () {
      if (isScrolledIntoView(jQuery(this))) {
        jQuery(this).addClass('animation-started');
      }
    });
  });
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = jQuery('header').outerHeight();
  jQuery(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = jQuery(window).scrollTop();

    // Zorg ervoor dat ze meer dan delta scrollen
    if (Math.abs(lastScrollTop - st) <= delta) return;

    // Als er naar beneden gescrolld is en voorbij de navbar, voeg klasse .nav-up toe en sluit het menu
    if (st > lastScrollTop && st > navbarHeight) {
      jQuery('header').removeClass('nav-down').addClass('nav-up');
      nav.removeClass('open'); // Sluit het menu bij scrollen
    } else {
      // Scroll omhoog, toon de navbar
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery('header').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  }
});